/* eslint-disable quote-props */
import React from 'react';
import { Script } from 'gatsby';
import { useLocation } from '@reach/router';
import useQueryParams from '../hooks/use-query-params';
import formatPhoneLink, { formatPhoneNumber } from '../components/utils/format-phone-link';

export enum DniBrand {
    INVOCA = 'INVOCA',
    TELMETRICS = 'TELMETRICS',
}

interface DniProviderReturnable {
    provider: DniBrand,
    setProvider: (provider: DniBrand) => void
    numberMap: Record<string, string>
}

const DniContext = React.createContext(null as unknown as DniProviderReturnable);

const InvocaRuntime = (): JSX.Element => (
    <Script
        id="invoca"
        dangerouslySetInnerHTML={{
            __html: `
                        (function (i, n, v, o, c, a) {
                          i.InvocaTagId = o;
                          var s = n.createElement('script');
                          s.type = 'text/javascript';
                          s.async = true;
                          s.src = ('https:' === n.location.protocol ? 'https://' : 'http://') + v;
                          var fs = n.getElementsByTagName('script')[0];
                          fs.parentNode.insertBefore(s, fs);
                        })(window, document, 'solutions.invocacdn.com/js/invoca-latest.min.js', '1905/2238540922');
                      `,
        }}
    />
);

export const telmetricsFranchiseMap: Record<string, Record<string, string>> = {
    '11402': {
        '3434651': '(631) 214-2068', // Huntington
        '3581819': '(631) 861-3384', // South Huntington (child of Huntington)
    },
    '1294': {
        '3475851': '(978) 361-2381', // Haverhill / Newburyport
    },
    '1793': {
        '3475852': '(978) 767-4627', // Danville / Ipswich
    },
    '11158': {
        '3474844': '(781) 928-8038', // Stoneham / Wakefield
    },
    '10313': {
        '3475679': '(804) 729-0295', // Richmond / Henrico County
        '3475674': '(804) 256-2585', // Henrico County (child of Richmond / Henrico)
    },
    '5248': {
        '3475673': '(804) 446-6921', // Tri-Cities Plus
        '3475672': '(804) 302-0845', // Chesterfield (child of Tri-Cities Plus)
    },
    '10097': {
        // SERVPRO of Jacksonville South
        '3474481-3': '(904) 723-8762',
        '3474481-1': '(904) 730-1145',
        '3474481-2': '(904) 760-5463',
    },
    '5581': {
        // SERVPRO of Providence
        '3475452': '(401) 735-2624',
        '3475451': '(401) 209-2912',
    },
    '5579': {
        '3475453': '(401) 443-3165', // SERVPRO of Cranston (child)
        '3475451': '(401) 715-0195', // SERVPRO of East Greenwich/Warwick
        '3475452': '(401) 203-5599',
    },
    '9512': {
        '3474170': '(925) 238-9135', // SERVPRO of Antioch
    },
    '11912': {
        '3605359': '(520) 613-2574', // SERVPRO of Northeast Tucson and SERVPRO of Oro Valley / Marana East
        '3605358': '(520) 492-8128',
    },
    '10650': {
        '3475344': '(503) 616-7054', // SERVPRO of Cedar Mill / Oak Hills
    },
    '11439': {
        '3539077': '(801) 701-1298', // SERVPRO of Utah County
        '3539058': '(385) 330-3416',
        '3539059': '(385) 330-3435',
        '3539060': '(385) 330-3415',
        '3539061': '(801) 701-3418',
        '3539076': '(801) 228-0472',
    },
    '10821': {
        '3494515': '(443) 406-2608', // SERVPRO of Cecil County
    },
    '10189': {
        '3475277': '(513) 538-1236', // SERVPRO of East Central Cincinnati
    },
    '11309': {
        '3523179': '(803) 408-5034', // SERVPRO of Southern Lancaster, Kershaw, and Fairfield Counties
    },
    '9144': {
        '3474520': '(941) 315-2241', // SERVPRO of Sarasota
    },
    '11303': {
        '3523185': '(704) 743-4347', // SERVPRO of South Cabarrus County
    },
    '5152': {
        '3475853': '(307) 224-1526', // SERVPRO of Casper
    },
    '11722': {
        '3584959': '(704) 533-9327', // SERVPRO of Uptown and East Charlotte
    },
    '11639': {
        '3582060': '(281) 640-8438', // SERVPRO of Cypress
        '3582060-b': '(281) 783-4978',
    },
    '10387': {
        '3474551_water': '(470) 375-1960', // SERVPRO of North Atlanta / Buckhead
        '3474551_storm': '(470) 223-0386',
        '3474551_fire': '(470) 305-5177',
        '3474551_brand': '(470) 482-6489',
    },
    '8664': {
        '3474688': '(309) 650-1058', // SERVPRO of North Central Tazewell County, Peoria, Galesburg and Macomb
        '3474661': '(309) 381-1275',
        '3474689': '(309) 424-4735',
    },
    '11127': {
        '3514888': '(813) 692-1128', // SERVPRO of Tampa Southeast
    },
    '8561': {
        '3474800': '(985) 646-8255', // SERVPRO of Slidell
    },
    '11744': {
        '3585730': '(702) 527-1906', // SERVPRO of Henderson / Boulder City
    },
    '11461': {
        '3539040': '(806) 639-8346', // SERVPRO of Amarillo
    },
    '11755': {
        '3602948': '(726) 215-9382', // SERVPRO of Olmos Park
    },
    '11476': {
        '3539050-p': '(630) 748-8327', // SERVPRO of Lombard / Addison,
        '3539050-b': '(630) 576-0498',
    },
    '11974': {
        '3609517': '(863) 377-2725', // SERVPRO of Winter Haven
    },
    '10156': {
        '3475240': '(347) 741-8559', // SERVPRO of Ozone Park / Jamaica Bay
    },
    '10982': {
        '3519730': '(516) 744-2405', // SERVPRO of New Hyde Park / Mineola
    },
    '11745': {
        '3584947': '(718) 971-9815', // SERVPRO of Northern Queens
    },
    '5819': {
        '3475782': '(607) 770-1831', // SERVPRO of Broome, Cortland, Chenango, Tompkins, Tioga, Chemung, Schuyler Counties
        '3475783': '(607) 744-6963', // SERVPRO of Broome County West
        '3604401': '(607) 306-2375', // SERVPRO of Cortland and Chenango Counties
        '3518637': '(607) 608-3158', // SERVPRO of Elmira/Chemung/Watkins/Schuyler
        '3475784': '(607) 269-8572', // SERVPRO of Tompkins and Tioga Counties
        '3643181': '(607) 821-7236',
    },
    '10643': {
        '3473249': '(951) 620-2096', // SERVPRO of Lake Elsinore / Perris / Menifee Northwest
    },
    '9455': {
        '3474116': '(334) 245-1258', // SERVPRO of Montgomery
    },
    '9457': {
        '3474125': '(334) 310-2795', // SERVPRO of Prattville
    },
    '10100': {
        '3474300': '(951) 605-5185', // SERVPRO of Woodcrest / El Cerrito / Lake Mathews
    },
    '12275': {
        '3474464': '(904) 875-3081', // SERVPRO of Fernandina Beach / Jacksonville Northeast
    },
    '9126': {
        '3474465': '(239) 322-1981', // SERVPRO of Central Ft. Myers
    },
    '10677': {
        '3474466': '(239) 790-4758', // SERVPRO of Bonita Springs / Estero
    },
    '9376': {
        '3474480': '(904) 723-8942', // SERVPRO of Arlington / Jacksonville East
        '3474480-1': '(904) 723-8942',
        '3474480-2': '(904) 723-8937',
        '3474480-3': '(904) 723-8761',
    },
    '9322': {
        '3474485': '(386) 406-8301', // SERVPRO of Columbia and Suwannee Counties
    },
    '8670': {
        '3474488': '(863) 329-2195', // SERVPRO of Lakeland
    },
    '9876': {
        '3474556': '(706) 229-7455', // SERVPRO of Gordon, Murray & South Whitfield Counties
    },
    '10259': {
        '3474560': '(470) 384-9016', // SERVPRO of Bartow County
    },
    '9754': {
        '3474564': '(470) 205-4209', // SERVPRO of Conyers / Covington
    },
    '8884': {
        '3474565': '(404) 890-5128', // SERVPRO of Paulding / Polk Counties
    },
    '5913': {
        '3474568': '(404) 575-4393', // SERVPRO of Douglasville
    },
    '10132': {
        '3474569': '(470) 381-3277', // SERVPRO of Fayette / S. Fulton Counties
    },
    '9265': {
        '3474575': '(470) 289-6516', //	SERVPRO of Marietta West
    },
    '10000': {
        '3474576': '(404) 369-3193', //	SERVPRO of E. Cobb
    },
    '10345': {
        '3474584': '(770) 835-4616', //	SERVPRO of North Lilburn
        '3536338': '(678) 837-2843',
    },
    '8515': {
        '3475044': '(704) 886-0556', //	SERVPRO of South Charlotte
    },
    '8204': {
        '3475167': '(201) 258-7592', //	SERVPRO of Northwest Bergen
    },
    '10674': {
        '3475278': '(513) 795-1618', //	SERVPRO of West Hamilton/Oxford
    },
    '8361': {
        '3475279': '(937) 540-2627', //	SERVPRO of Northwest Dayton
    },
    '8544': {
        '3475280': '(937) 203-4485', //	SERVPRO of West Dayton/Preble County
    },
    '9214': {
        '3475452': '(617) 341-9580', //	SERVPRO of Boston Downtown/Back Bay/South Boston
        '3524678': '(617) 500-0888',
    },
    '10967': {
        '3475452': '(781) 995-4428', //	SERVPRO of Burlington/Woburn
    },
    '11134': {
        '3475457': '(864) 532-2605', //	SERVPRO of Anderson
    },
    '10093': {
        '3475621': '(210) 265-6595', //	SERVPRO of Braun Station
    },
    '10660': {
        '3475626': '(210) 817-5935', //	SERVPRO of Alamo Heights
    },
    '10699': {
        '3482975': '(352) 675-4348', //	SERVPRO of Hernando County
    },
    '10707': {
        '3483009': '(843) 534-5218', //	SERVPRO of Downtown Charleston and Mount Pleasant
        '3631644': '(854) 227-3268', // 12029 (child)
    },
    '10700': {
        '3483020': '(813) 825-1082', //	SERVPRO of Wesley Chapel
    },
    '10698': {
        '3483042': '(727) 758-7219', //	SERVPRO of West Pasco
    },
    '11182': {
        '3516076': '(404) 592-9634', //	SERVPRO of South Atlanta
    },
    '10981': {
        '3517437': '(704) 771-1548', //	SERVPRO of East Mecklenburg/Matthews
    },
    '11129': {
        '3519648': '(813) 692-1095', //	SERVPRO of Greater Carrollwood/Citrus Park
    },
    '11126': {
        '3519650': '(813) 692-3625', //	SERVPRO of North Tampa/Magdalene
    },
    '11098': {
        '3519664': '(904) 719-6284', //	SERVPRO of North Clay County/Oakleaf/North Middleburg
    },
    '11169': {
        '3523137': '(704) 831-7985', //	SERVPRO of Northeast Charlotte
    },
    '11259': {
        '3523210': '(864) 916-9027', //	SERVPRO of Oconee
    },
    '12274': {
        '3539053': '(904) 204-4851', //	SERVPRO of Jacksonville Northwest
    },
    '11469': {
        '3539055': '(813) 547-4244', //	SERVPRO of New Tampa
    },
    '11608': {
        '3579838': '(830) 410-1654', // SERVPRO of Medina County
    },
    '11601': {
        '3580119': '(478) 475-2034', // SERVPRO of Macon
    },
    '11603': {
        '3580121': '(478) 699-2089', // SERVPRO of Thomaston, Forsyth & Fort Valley
    },
    '11692': {
        '3583670': '(863)-510-2648', // SERVPRO of Bartow/Lakeland
    },
    '11724': {
        '3585722': '(689) 488-1392', // SERVPRO of Sanford East
    },
    '11725': {
        '3585723': '(386) 516-4967', // SERVPRO of Deltona North
    },
    '11746': {
        '3585731': '(704) 831-9218', // SERVPRO of South Mecklenburg County
    },
    '11759': {
        '3585733': '(386) 327-2082', // SERVPRO of DeLand
    },
    '11760': {
        '3585734': '(689) 308-0136', // SERVPRO of Lake Mary/Heathrow
    },
    '11708': {
        '3586759': '(864) 810-4741', // SERVPRO of Pickens County
    },
    '11709': {
        '3586760': '(864) 535-6429', // SERVPRO of South Greenville County
    },
    '11758': {
        '3586791': '(770) 648-1195', // SERVPRO of Stone Mountain
    },
    '11756': {
        '3586793': '(470) 731-4362', // SERVPRO of Gwinnett County South
    },
    '11877': {
        '3603691': '(980) 295-1875', //	SERVPRO of Shelby
    },
    '11900': {
        '3605159': '(803) 745-7015', //	SERVPRO of Indian Land, Cherokee, Union, and Chester
    },
    '12208': {
        '3631649': '(336) 904-3934', //	SERVPRO of Greensboro West
    },
    '12084': {
        '3634886': '(770) 824-0937', //	SERVPRO of Southwest Cobb
    },
    '12055': {
        '3634898': '(770) 796-5213', //	SERVPRO of Southeast Cobb
    },
    '10560': {
        '6475469': '(864) 686-7431', //	SERVPRO of West Greenville
    },
    '11255': {
        '9474363': '(951) 528-1854', //	SERVPRO of Temecula
    },
    '12277': {
        '3474478': '(904) 242-6743', //	SERVPRO of Jacksonville Beach
    },
    '12276': {
        '3474477': '(904) 638-2495', // SERVPRO of Mandarin
    },
    '11481': {
        '3474502-3': '(904) 650-8005',
        '3474502-2': '(904) 323-4998',
        '3474502-1': '(904) 204-1190',
    },
    '8557': {
        '3475877': '(401) 415-6988', //	SERVPRO of Northern Rhode Island
    },
    '8559': {
        '3474842': '(508) 960-9740', //	SERVPRO of Southern Worcester
    },
    '8558': {
        '3474804': '(774) 331-3520', //	SERVPRO of The Attleboros
    },
    '11454': {
        '3539067': '(305) 909-8346', //	SERVPRO of South Miami
    },
    '11455': {
        '3539023': '(305) 939-4035', // SERVPRO of Cutler Bay
    },
    '11456': {
        '3539024': '(561) 925-6365', //	SERVPRO of Central Palm Beach
    },
    '9486': {
        '3474555_brand': '(470) 202-1005', // SERVPRO of Buford
        '3474555_water': '(470) 202-4008',
        '3474555_fire': '(470) 202-4030',
        '3474555_storm': '(470) 202-4204',
    },
    '10626': {
        '3474600_brand': '(470) 226-3999', // SERVPRO of Cherokee County
        '3474600_water': '(470) 242-1048',
        '3474600_storm': '(470) 509-3049',
        '3474600_fire': '(470) 242-7311',
    },
    '10627': {
        '3474545_brand': '(470) 239-5012', // SERVPRO of Forsyth And Dawson Counties
        '3474545_fire': '(470) 239-1688',
        '3474545_storm': '(470) 239-1704',
        '3474545_water': '(470) 201-5322',
    },
    '10388': {
        '3474541_brand': '(470) 336-6149', // SERVPRO of NW Cobb
        '3474541_fire': '(470) 336-3765',
        '3474541_storm': '(470) 336-4103',
        '3474541_water': '(470) 336-5175',
    },
    '10029': {
        '3474549_brand': '(470) 509-3600', // SERVPRO of NW Dekalb
        '3474549_fire': '(470) 509-3323',
        '3474549_storm': '(470) 509-4010',
        '3474549_water': '(470) 509-3223',
    },
    '8990': {
        '3474571': '(470) 203-2439', // SERVPRO of Troup
    },
    '11545': {
        '3578871': '(216) 468-5192', // SERVPRO of Beachwood and Cleveland Northeast
        '3578870': '(216) 931-0316', // 11546 (child)
        '3539361': '(216) 785-2264', // 11527 (child)
        '3539362': '(216) 785-2182', // 11526 (child)
    },
    '11264': {
        '3519605': '(330) 556-6135', // SERVPRO of New Philadelphia
    },
    '12170': {
        '3474583': '(678) 981-6915', // SERVPRO of Norcross and Duluth
    },
    '11713': {
        '3588299': '(847) 534-5315', // SERVPRO of Elk Grove / E. Schaumburg / Itasca / Roselle
    },
    '12088': {
        '3635256': '(919) 336-2419', // SERVPRO of Cary / Morrisville / Apex
        '3635254': '(919) 980 6061', // 12090 (child)
        '3635250': '(919) 912 9028', // 12100 (child)
        '3635256-b': '(984) 306-0128',
        '3635256-w': '(984) 306-0143',
        '3635250-b': '(984) 584-2254',
        '3635254-w': '(984) 370-4593',
        '3635250-w': '(984) 584-3949',
        '3635254-b': '(984) 370-3973',
    },
    '12093': {
        '3635251': '(984) 326-3584', // SERVPRO of North Raleigh & Wake Forest,
        '3635248': '(984) 789-4481', // 12102 (child)
        '3635249': '(984) 884-3598', // 12101 (child)
        '3635251-b': '(984) 401-9084',
        '3635251-w': '(984) 401-9361',
        '3635249-b': '(984) 308-8467',
        '3635249-w': '(984) 308-9217',
        '3635248-b': '(984) 263-3508',
        '3635248-w': '(984) 263-5254',
    },
    '12092': {
        '3635252': '(984) 308-8258', // SERVPRO of Southeast Raleigh, Garner
        '3635252-b': '(984) 480-2954',
        '3635252-w': '(984) 480-3375',
    },
    '12089': {
        '3635255': '(919) 980-6044', // SERVPRO of East Raleigh, Zebulon, Knightdale
        '3635255-b': '(984) 313-4125',
        '3635255-w': '(984) 313-4206',
    },
    '12091': {
        '3635253': '(984) 250-8915', // SERVPRO of North Durham
        '3635253-b': '(984) 278-6358',
        '3635253-w': '(984) 278-7256',
    },
    '19057': {
        '3635246': '(984) 833-4687',
    },
    '9333': {
        '3474410': '(719) 924-1210', // SERVPRO of Pueblo
    },
    '11349': {
        '3474580': '(678) 554-3145', // SERVPRO of Henry, Spalding, Butts and Clayton Counties
    },
    '11067': {
        '3514699': '(615) 903-9245', // SERVPRO of Rutherford County
        '3514700-b': '(615) 728-3515',
        '3514700-w': '(615) 728-3187',
    },
    '19007': {
        '3605342': '(615) 685-2963', // SERVPRO of Downtown Nashville / Team Bisig
        '3605342-b': '(615) 747-7291',
        '3605342-w': '(615) 747-6557',
    },
    '11078': {
        '3514701': '(615) 861-2997', // SERVPRO of Williamson County
        '3514701-b': '(615) 608-6584',
        '3514701-w': '(615) 608-6382',
    },
    '11079': {
        '3475530': '(931) 398-3794', // SERVPRO of Maury / Giles Counties
        '3475530-b': '(931) 350-6104',
        '3475530-w': '(931) 350-6075',
    },
    '11068': {
        '3514677': '(629) 215-8105', // SERVPRO of Southeast Nashville
        '3514677-b': '(615) 747-9298',
        '3514677-w': '(615) 747-2823',
    },
    '11357': {
        '3523152': '(307) 228-1865', // SERVPRO of Gillette, Sheridan
    },
    '10549': {
        '3475485': '(605) 863-9036', // SERVPRO of Rapid City, Spearfish
    },
    '5576': {
        '3475779': '(205) 259-7895', // SERVPRO of Birmingham
        '3475779-b': '(205) 378-8545',
        '3475775': '(205) 354-2578', // 11186
        '3475775-b': '(205) 624-9165',
        '3475774': '(205) 715-8134', // 11185
        '3475774-b': '(205) 825-8786',
        '3475778': '(205) 533-8087', // 8927
        '3475778-b': '(205) 390-7698',
        '3475777': '(205) 623-1416', // 5575
        '3475777-b': '(205) 855-2095',
        '3475776': '(205) 417-4034', // 5574
        '3475776-b': '(205) 519-3054',
    },
    '10065': {
        '3474805': '(978) 867-8078', // SERVPRO of Beverly / Cape Ann
    },
    '10796': {
        '3493470': '(706) 750-9476', // SERVPRO of Augusta
    },
    '12079': {
        '3634890': '(803) 761-7925', // SERVPRO of Aiken
    },
    '10794': {
        '3494537': '(706) 842-3315', // SERVPRO of Columbia County
    },
    '12087': {
        '3634883': '(803) 336-5507', // SERVPRO of North Augusta
    },
    '11214': {
        '3517737': '(209) 642-4521', // SERVPRO of Amador and Calaveras County
    },
    '12134': {
        '3639385': '(503) 755-1019', // SERVPRO of Gresham
    },
    '12135': {
        '3639386': '(971) 253-6978', // SERVPRO of Oregon City / Sandy
    },
    '12136': {
        '3639387': '(971) 431-5835', // SERVPRO of North East Portland
    },
    '11297': {
        '3519258': '(503) 470-6734', // SERVPRO of Clatsop, Columbia Counties
    },
    '9430': {
        '3475834': '(435) 772-9138', // SERVPRO of St George
    },
    '10661': {
        '3475418': '(724) 371-1478', // SERVPRO of Beaver County
    },
    '10669': {
        '3475797': '(615) 581-7158', // SERVPRO of Sumner County
    },
    '8267': {
        '3475798': '(615) 985-1478', // SERVPRO of Cheatham, Robertson and Dickson Counties
    },
    '19014': {
        '3605335': '(210) 229-8033', // SERVPRO of Downtown San Antonio/Team Friermuth
    },
    '11789': {
        '3586786': '(737) 315-3685', // SERVPRO of San Marcos, New Braunfels, Kyle, Buda
        '3586781': '(737) 215-3175', // (11795) SERVPRO of Southern Travis County, Lockhart
        '3586782': '(830) 359-3595', // (11794) SERVPRO of New Braunfels
        '3586783': '(737) 358-2068', // (11793) SERVPRO of Kyle, Buda
        '3586784': '(830) 326-5001', // (11792) SERVPRO of Dripping Springs, Canyon Lake, Bulverde
    },
    '9142': {
        '3475715': '(253) 434-4296', // SERVPRO of Puyallup/Sumner
    },
    '11273': {
        '3519598': '(509) 516-1678', // SERVPRO of Tri Cities West | SERVPRO of Franklin County
        '3523206': '(509) 940-1065', // SERVPRO of Tri Cities West
    },
    '10522': {
        '3475716': '(360) 464-2629', // SERVPRO of Lacey
    },
    '5805': {
        '3475714': '(253) 793-7984', // SERVPRO of Auburn/Enumclaw
    },
    '11330': {
        '3522237': '(817) 840-3419', // SERVPRO of North Fort Worth
    },
    '8722': {
        '3475635': '(254) 730-7464', // SERVPRO of Waco
    },
    '10800': {
        '3494533': '(254) 342-2405', // SERVPRO of Greater Waco
    },
    '10939': {
        '3514697': '(726) 245-9682', // SERVPRO of East San Antonio
    },
    '12030': {
        '3631643': '(361) 317-3362', // SERVPRO of Cuero, Hallettsville, Edna
    },
    '11781': {
        '3586788': '(726) 610-3125', // SERVPRO of Universal City/St. Hedwig
    },
    '11423': {
        '3531320': '(830) 301-5068', // SERVPRO of La Vernia, Pleasanton
    },
    '11089': {
        '3516058': '(915) 229-6852', // SERVPRO of Southeast El Paso/Butterfield
    },
    '12031': {
        '3475810': '(512) 646-4267', // SERVPRO of Hyde Park, Central Austin
    },
    '12033': {
        '3519667': '(512) 806-7156', // SERVPRO of Lake Travis, Lakeway
    },
    '12032': {
        '3519694': '(512) 861-0156', // SERVPRO of NW Austin, N Bee Cave
    },
    '10670': {
        '3474623': '(208) 252-6574', // SERVPRO of Blackfoot/Pocatello
    },
    '10428': {
        '3475809': '(802) 738-0931', // SERVPRO of Windham & Windsor Counties
    },
    '5455': {
        '3475812': '(603) 283-6824', // SERVPRO of Cheshire County
    },
    '10005': {
        '3475188': '(702) 765-4464', // SERVPRO of Downtown Las Vegas
    },
    '10289': {
        '3475186': '(702) 763-8026', // SERVPRO of Summerlin North/Sun City
    },
    '10288': {
        '3475189': '(702) 748-7954', // SERVPRO of Northwest Las Vegas
    },
    '8284': {
        '3474814': '(413) 324-8819',
        '3474814-m': '(413) 640-2275',
    },
    '10106': {
        '3474543': '(229) 461-6312', // SERVPRO of Albany
    },
    '11684': {
        '3583662': '(229) 800-1254', // SERVPRO of Bainbridge, Blakely, Camilla
    },
    '11966': {
        '3608953': '(508) 802-4018', // SERVPRO of Taunton/Mansfield
        '3609522': '(774) 991-6325', // SERVPRO of Seekonk, Dighton
    },
    '10952': {
        '3519755': '(945) 523-2428', // SERVPRO of Carrollton
    },
    // DON'T FORGET TO ADD NEW NUMBERS TO 'DO NOT REPLACE' LIST IN INVOCA
    '1739': { '3474562': '(706) 530-9263' },
    '5769': { '3475245': '(585) 565-9815' },
    '5925': { '3475198': '(845) 765-3364' },
    '5952': { '3475780': '(417) 799-9542' },
    '8333': {
        '3474948': '(989) 607-5875',
        '3474949': '(989) 402-2178',
        '3494464': '(989) 750-0035',
        '3516478': '(989) 494-4135',
    },
    '8773': { '3475781': '(417) 595-4591' },
    '9207': { '3475199': '(845) 256-8258' },
    '9233': { '3475704': '(206) 460-0638' },
    '9264': { '3474100': '(256) 378-9548' },
    '9392': { '3474127': '(334) 384-1736' },
    '9423': { '3475520': '(629) 736-3645' },
    '9573': { '3475722': '(206) 338-0146', '3603694': '(206) 209-0058' },
    '9592': { '3475135': '(201) 409-8940' },
    '9724': { '3474101': '(205) 814-6135' },
    '9746': { '3475723': '(206) 339-0207' },
    '9913': { '3474845': '(508) 365-6434' },
    '9945': { '3475824': '(801) 285-9424' },
    '10034': { '3474128': '(334) 744-6536' },
    '10225': { '3475708': '(253) 638-4536' },
    '10358': { '3475825': '(801) 992-1285' },
    '10401': { '3474243': '(626) 341-1748' },
    '10451': { '3474524': '(904) 436-9015' },
    '10513': {
        '3475259': '(234) 430-4195',
        '3475324': '(330) 536-9625',
        '3519606': '(330) 921-4157',
    },
    '10591': { '3474846': '(508) 318-6051' },
    '10615': {
        '3474950': '(734) 992-5303',
        '3474951': '(734) 530-1034',
        '3483036': '(734) 713-3935',
    },
    '10622': { '3475260': '(440) 650-1032', '3475316': '(330) 974-1195' },
    '10961': { '3519747': '(716) 671-1101' },
    '10962': { '3519746': '(706) 576-7586' },
    '10998': { '3519687': '(724) 498-4784', '3519719': '(724) 704-3215' },
    '11033': { '3519698': '(716) 501-8957' },
    '11057': { '3519003': '(801) 997-9846' },
    '11058': { '3519004': '(801) 780-1353' },
    '11075': { '3519674': '(629) 702-9382' },
    '11076': { '3519673': '(629) 895-2852' },
    '11080': { '3518658': '(732) 487-3095' },
    '11093': { '3475391': '(814) 841-1975', '3514856': '(814) 636-6574' },
    '11110': { '3474899': '(248) 365-7319', '3516479': '(586) 480-2763' },
    '11199': { '3516297': '(904) 290-2038' },
    '11261': { '3519607': '(814) 350-2584' },
    '11281': {
        '3523198': '(757) 731-5675',
        '3523200': '(757) 765-1237',
        '3523201': '(757) 929-7064',
        '3581025': '(757) 731-5628',
        '3581026': '(757) 972-2306',
    },
    '11283': { '3523199': '(252) 580-5381' },
    '11539': { '3577184': '(425) 504-6456', '3603704': '(425) 504-6602' },
    '11566': { '3579871': '(260) 271-8551' },
    '11631': { '3583019': '(386) 348-3749' },
    '11632': { '3583020': '(321) 291-2023' },
    '11787': { '3588291': '(732) 812-5038' },
    '11855': { '3603693': '(425) 437-3345', '3603706': '(425) 598-8075' },
    '11856': { '3603695': '(206) 201-2952', '3603705': '(206) 339-0414' },
    '11858': { '3603703': '(425) 428-4046' },
    '11897': { '3604399': '(570) 880-0961', '3604400': '(570) 880-0864' },
    '11961': { '3609527': '(518) 943-8235' },
    '11973': {
        '3474900': '(248) 621-4145',
        '3609518': '(248) 397-5786',
        '3609807': '(248) 309-8851',
    },
    '12022': { '3631651': '(256) 364-5275' },
    '12076': { '3634529': '(978) 483-0895' },
    '12180': { '3640139': '(724) 517-7136' },
    '12192': { '3475565': '(940) 334-7156' },
    '12193': { '3519601': '(405) 497-8151' },
    '12194': { '3641083': '(572) 202-3267' },
    '19038': {
        '3608948': '(412) 474-2015',
        '3608949': '(412) 538-5191',
        '3608950': '(412) 552-3452',
        '3608956': '(412) 474-2098',
    },
    '19054': { '3516477': '(313) 379-6312' },
    '5539': { '3475747': '(262) 426-8036' }, // SERVPRO of Lake Geneva
    '10290': {
        '3475744': '(608) 607-4375', // SERVPRO of South Rock County
        '3475749': '(608) 905-6045', // SERVPRO of North Rock County (10339)
    },
    '12278': { '3644317': '(904) 902-8823' },
    '12279': { '3644316': '(904) 428-0420' },
    '1509': {
        '3475194': '(716) 213-6616',
    },
    '5135': {
        '3474837': '(781) 869-2536',
        '3474838': '(781) 290-6248',
        '3580952': '(617) 684-6483',
    },
    '5799': {
        '3475195': '(716) 235-2366',
    },
    '5817': {
        '3474554': '(912) 617-8704',
    },
    '5825': {
        '3475196': '(716) 249-4026',
    },
    '5897': {
        '3474599': '(912) 900-3145',
    },
    '8217': {
        '3475220': '(716) 646-8059',
    },
    '8219': {
        '3474559': '(762) 239-0045',
    },
    '8220': {
        '3475374': '(484) 720-0345',
    },
    '8309': {
        '3474953': '(269) 487-9971',
    },
    '8452': {
        '3474440': '(610) 609-8969',
    },
    '8481': {
        '3478420': '(978) 341-3075',
    },
    '8662': {
        '3475545': '(979) 977-1085',
    },
    '8667': {
        '3474134': '(870) 604-4725',
    },
    '8699': {
        '3474923': '(989) 614-4185',
    },
    '8846': {
        '3474955': '(231) 498-7035',
    },
    '8872': {
        '3475332': '(405) 928-1396',
    },
    '8923': {
        '3515577': '(231) 518-4082',
    },
    '8954': {
        '3475187': '(702) 659-5047',
    },
    '9045': {
        '3474893': '(207) 806-2904',
    },
    '9059': {
        '3475632': '(254) 483-3082',
    },
    '9066': {
        '3475007': '(816) 396-8857',
    },
    '9086': {
        '3474441': '(302) 440-4654',
    },
    '9105': {
        '3474442': '(302) 724-4838',
    },
    '9188': {
        '3474901': '(269) 841-5825',
    },
    '9229': {
        '3474769': '(606) 401-0985',
    },
    '9236': {
        '3475287': '(419) 861-2614',
    },
    '9271': {
        '3475115': '(603) 821-9248',
    },
    '9272': {
        '3475105': '(603) 262-3724',
    },
    '9307': {
        '3475325': '(740) 872-7118',
    },
    '9309': {
        '3474132': '(501) 426-6935',
    },
    '9310': {
        '3474296': '(310) 807-7723',
    },
    '9315': {
        '3475038': '(406) 215-1925',
    },
    '9320': {
        '3475190': '(775) 229-4158',
    },
    '9349': {
        '3474192': '(619) 371-5184',
    },
    '9458': {
        '3475585': '(281) 825-3042',
    },
    '9590': {
        '3482813': '(859) 267-1807',
    },
    '9599': {
        '3475729': '(253) 993-4615',
    },
    '9634': {
        '3474721': '(219) 240-1182',
    },
    '9649': {
        '3474690': '(217) 919-2461',
    },
    '9657': {
        '3475292': '(740) 395-0423',
    },
    '9668': {
        '3474939': '(734) 799-8025',
    },
    '9792': {
        '3475640': '(801) 335-9375',
    },
    '9874': {
        '3474916': '(517) 721-7904',
    },
    '9951': {
        '3474849': '(410) 793-1548',
    },
    '9969': {
        '3475738': '(715) 702-5488',
    },
    '9973': {
        '3474864': '(443) 607-2136',
    },
    '9980': {
        '3475476-1': '(803) 597-2039',
        '3475476-2': '(864) 766-4265',
    },
    '10006': {
        '3475850': '(912) 454-5167',
    },
    '10013': {
        '3475492': '(931) 367-2035',
    },
    '10014': {
        '3475526': '(731) 540-7186',
    },
    '10116': {
        '3475849': '(478) 287-4184',
    },
    '10135': {
        '3475811': '(812) 291-1614',
    },
    '10157': {
        '3474827': '(508) 938-2285',
    },
    '10158': {
        '3474828': '(508) 979-6075',
    },
    '10173': {
        '3475641': '(801) 797-1325',
    },
    '10195': {
        '3474791': '(337) 401-3231',
    },
    '10197': {
        '3475638': '(435) 414-0572',
    },
    '10224': {
        '3475756': '(262) 332-4893',
    },
    '10228': {
        '3475037': '(406) 204-3636',
    },
    '10232': {
        '3474597': '(229) 929-4825',
    },
    '10268': {
        '3475646': '(801) 736-4777',
    },
    '10269': {
        '3475730': '(253) 617-3658',
    },
    '10271': {
        '3475330': '(405) 363-5915',
        '3585748': '(405) 920-1745',
    },
    '10283': {
        '3474863': '(410) 774-5095',
    },
    '10291': {
        '3474273': '(831) 583-8075',
    },
    '10293': {
        '3475750': '(608) 224-7229',
    },
    '10331': {
        '3474567': '(678) 500-8777',
    },
    '10340': {
        '3475486': '(605) 368-1045',
    },
    '10351': {
        '3475493': '(931) 650-5621',
    },
    '10360': {
        '3475272': '(440) 557-2773',
    },
    '10371': {
        '3474724': '(765) 340-2965',
    },
    '10382': {
        '3474680': '(847) 410-1465',
    },
    '10399': {
        '3475771': '(307) 509-5290',
    },
    '10419': {
        '3475593': '(956) 277-2901',
    },
    '10429': {
        '3474456': '(727) 513-8915',
    },
    '10430': {
        '3474526': '(727) 349-0153',
    },
    '10452': {
        '3474803': '(413) 372-1845',
    },
    '10478': {
        '3475216': '(716) 303-7694',
    },
    '10492': {
        '3475517': '(901) 307-2645',
    },
    '10532': {
        '3474988': '(660) 240-8764',
    },
    '10543': {
        '3474093': '(256) 240-0955',
    },
    '10584': {
        '3475742': '(715) 624-4045',
    },
    '10637': {
        '3475544': '(956) 594-4157',
    },
    '10679': {
        '3475334': '(405) 937-2354',
    },
    '10703': {
        '3482966': '(931) 202-8405',
    },
    '10719': {
        '3483035': '(505) 527-9165',
    },
    '10760': {
        '3483044': '(469) 215-2582',
    },
    '10823': {
        '3491427': '(508) 618-5832',
    },
    '10829': {
        '3494510': '(405) 216-4977',
        '3539032': '(405) 215-9893',
    },
    '10881': {
        '3519777': '(270) 767-6976',
    },
    '10888': {
        '3494468': '(865) 518-6946',
    },
    '10896': {
        '3494462': '(410) 317-4341',
    },
    '10897': {
        '3494461': '(972) 598-9463',
    },
    '10912': {
        '3494455': '(540) 513-5074',
    },
    '10919': {
        '3494449': '(443) 737-4135',
    },
    '10930': {
        '3519771': '(256) 562-4003',
    },
    '10945': {
        '3515163': '(812) 463-3496',
    },
    '10957': {
        '3635246-b': '(984) 263-2465',
        '3635246-w': '(984) 263-5292',
    },
    '10975': {
        '3519736': '(262) 264-6796',
    },
    '10981': {
        '3517437': '(704) 771-1548',
    },
    '11088': {
        '3519668': '(407) 513-4997',
    },
    '11105': {
        '3519661': '(901) 390-4234',
    },
    '11106': {
        '3519660': '(901) 730-4945',
    },
    '11107': {
        '3519659': '(901) 762-1064',
    },
    '11128': {
        '3519649': '(407) 743-4230',
    },
    '11130': {
        '3519647': '(727) 732-2135',
    },
    '11135': {
        '3475647': '(801) 935-8936',
    },
    '11180': {
        '3518257': '(573) 381-5035',
    },
    '11181': {
        '3475210': '(585) 577-6528',
    },
    '11215': {
        '3475660': '(434) 977-5439',
    },
    '11219': {
        '3519617': '(801) 896-9428',
    },
    '11223': {
        '3523222': '(505) 395-9084',
    },
    '11237': {
        '3519613': '(414) 434-7965',
        '3581399': '(262) 895-2226',
    },
    '11245': {
        '3523218': '(931) 616-0724',
    },
    '11274': {
        '3519797': '(270) 455-1076',
    },
    '11275': {
        '3519602': '(812) 202-4051',
    },
    '11286': {
        '3523197': '(601) 706-1225',
    },
    '11293': {
        '3523192': '(410) 787-5303',
    },
    '11351': {
        '3474507': '(407) 796-9685',
    },
    '11352': {
        '3523156': '(915) 248-1856',
    },
    '11365': {
        '3523144': '(682) 738-4587',
    },
    '11366': {
        '3523143': '(682) 712-0749',
    },
    '11369': {
        '3523140': '(817) 422-5028',
    },
    '11370': {
        '3524677': '(817) 522-4538',
    },
    '11371': {
        '3524720': '(817) 252-4666',
    },
    '11385': {
        '3526523': '(262) 229-6948',
    },
    '11400': {
        '3532549': '(781) 536-7265',
    },
    '11419': {
        '3475124': '(973) 928-7593',
    },
    '11424': {
        '3531319': '(316) 252-1989',
    },
    '11497': {
        '3539033': '(517) 879-2458',
    },
    '11547': {
        '3577179': '(512) 549-8137',
    },
    '11606': {
        '3579840': '(727) 263-4087',
    },
    '11609': {
        '3580143': '(407) 848-5455',
    },
    '11625': {
        '3580957': '(901) 666-5184',
    },
    '11641': {
        '3583652': '(209) 831-7075',
    },
    '11649': {
        '3582052': '(716) 952-3158',
    },
    '11706': {
        '3585720': '(601) 550-6594',
        '3585721': '(601) 329-2940',
    },
    '11710': {
        '3602950': '(507) 322-1498',
    },
    '11717': {
        '3588295': '(252) 436-7648',
    },
    '11786': {
        '3588292': '(573) 501-3045',
    },
    '11836': {
        '3603659': '(706) 512-8495',
    },
    '11841': {
        '3606944': '(920) 541-1754',
        '3606945': '(262) 793-0698',
    },
    '11884': {
        '3608340': '(717) 947-3624',
        '3608341': '(717) 925-7217',
    },
    '11947': {
        '3608346': '(201) 636-1151',
    },
    '11954': {
        '3608945': '(816) 754-2794',
    },
    '11972': {
        '3609519': '(931) 575-5084',
    },
    '11982': {
        '3609510': '(585) 504-7814',
        '3609510-1': '(585) 565-1135',
    },
    '11994': {
        '3611766': '(423) 805-1495',
    },
    '12017': {
        '3633080': '(256) 915-2146',
    },
    '12018': {
        '3633079': '(205) 430-2234',
    },
    '12019': {
        '3633078': '(205) 512-4675',
    },
    '12049': {
        '3643472': '(858) 203-9893',
    },
    '12050': {
        '3474322': '(858) 215-5290',
    },
    '12051': {
        '3633065': '(814) 420-2567',
    },
    '12105': {
        // https://www.servpro.com/locations/fl/servpro-of-gainesville-west-alachua-county-west
        '3638528': '(352) 565-9265',
        '3638529': '(352) 922-5765', // SERVPRO of Alachua County West (12107 - Child of 12105)
    },
    '12127': {
        '3643445': '(507) 214-6431',
    },

    '12142': {
        '3639359': '(262) 675-8864',
    },
    '12162': {
        '3642681': '(310) 878-3156',
    },
    '12165': {
        '3643414': '(507) 964-4792',
    },
    '12166': {
        '3643413': '(320) 640-8178',
    },
    '12174': {
        '3475212': '(585) 270-7662',
    },
    '12175': {
        '3482989': '(585) 633-7146',
    },
    '12200': {
        '3639941': '(585) 627-0076',
    },
    '12207': {
        '3642542': '(336) 866-3028',
    },
    '12231': {
        '3641965': '(989) 340-3085',
    },
    '12232': {
        '3642302': '(360) 972-7219',
    },
    '12236': {
        '3494543': '(812) 916-4856',
    },
    '12242': {
        '3643041': '(336) 849-5195',
    },
    '12273': {
        '3475030': '(662) 636-6345',
    },
    '12299': {
        '3475760': '(608) 497-8047',
    },
    '12304': {
        '3475712': '(425) 510-0514',
    },
    '12305': {
        '3475711': '(425) 409-0645',
    },
    '19002': {
        '3603680': '(801) 406-9864',
    },
    '19021': {
        '3605328': '(801) 512-2335',
    },
    '5408': {
        '3475289': '(234) 269-4075',
    },
    '5699': {
        '3475290': '(330) 552-5162',
    },
    '8919': {
        '3475309': '(330) 838-6105',
    },
    '10946': {
        '3519761': '(239) 467-3024',
    },
    '11553': {
        '3474598': '(770) 854-0204',
    },

    '5727': {
        // https://www.servpro.com/locations/tn/servpro-of-dyersburg-union-city-weakley-gibson-carroll-counties
        '3475523': '(731) 628-9297', // SERVPRO of Dyersburg/Union City (5727 - Parent)
        '3475524': '(731) 273-3134', // SERVPRO of Weakley, Gibson, Carroll Counties (8749 - Child of 5727)
    },
    '8200': {
        // https://www.servpro.com/locations/va/servpro-of-new-kent-williamsburg-northern-neck
        '3475658': '(804) 488-9087', // SERVPRO of Kent/Williamsburg/Northern Neck (8200 - Parent)
    },
    '8462': {
        // https://www.servpro.com/locations/tn/servpro-of-jackson-crockett-county
        '3475504': '(731) 513-7291', // SERVPRO of Jackson/Crockett County (8462 - Parent)
    },
    '8805': {
        // https://www.servpro.com/locations/va/servpro-of-york-james-city-county-poquoson
        '3475659': '(757) 703-9159', // SERVPRO of York/James City County/Poquoson (8805 - Parent)
    },
    '8912': {
        // https://www.servpro.com/locations/ca/servpro-of-lodi
        '3474253': '(209) 642-4615', // SERVPRO of Lodi (8912 - Parent)
    },
    '9594': {
        // https://www.servpro.com/locations/tn/servpro-of-chattanooga
        '3475525': '(423) 468-9748', // SERVPRO of East Chattanooga (9594 - Parent)
        '3475490': '(423) 874-7965', // SERVPRO of South Chattanooga (9915 - Child of 9594)
        '3475528': '(423) 475-7459', // SERVPRO of North Chattanooga (10141 - Child of 9594)
    },
    '10073': {
        // https://www.servpro.com/locations/ny/servpro-of-farmingdale-massapequa
        '3475217': '(516) 718-8025', // SERVPRO of Farmingdale Massapequa (10073 - Parent)
    },
    '10592': {
        // https://www.servpro.com/locations/tn/servpro-of-bradley-county
        '3475491': '(423) 813-8045', // SERVPRO of Bradley County (10592 - Parent)
    },
    '10731': {
        // https://www.servpro.com/locations/ny/servpro-of-south-bronx
        '3482980': '(347) 795-8265', // SERVPRO of South Bronx (10731 - Parent)
    },
    '10765': {
        // https://www.servpro.com/locations/tn/servpro-of-wilson-county
        '3482959': '(615) 647-9628', // SERVPRO of Wilson County (10765 - Parent)
    },
    '10902': {
        // https://www.servpro.com/locations/tn/servpro-of-east-nashville-madison-goodlettsville
        '3475515': '(615) 866-3430', // SERVPRO of East Nashville, Madison, Goodlettsville (10902 - Parent)
    },
    '10903': {
        // https://www.servpro.com/locations/tn/servpro-of-south-nashville
        '3475489': '(615) 931-0238', // SERVPRO of South Nashville (10903 - Parent)
    },
    '11019': {
        // https://www.servpro.com/locations/in/servpro-of-columbus-and-servpro-of-jackson-jennings-counties
        '3474713': '(812) 445-1185', // SERVPRO of Columbus (11019 - Parent)
    },
    '11229': {
        // https://www.servpro.com/locations/tn/servpro-of-west-knoxville
        '3521459': '(865) 247-8729', // SERVPRO of West Knoxville (11229 - Parent)
        '3521468': '(865) 332-2164', // SERVPRO of Northwest Knoxville (11231 - Child of 11229)
    },
    '11230': {
        // https://www.servpro.com/locations/tn/servpro-of-oak-ridge
        '3521465': '(865) 294-9134', // SERVPRO of Oak Ridge (11230 - Parent)
    },
    '11314': {
        // https://www.servpro.com/locations/tn/servpro-of-north-knoxville
        '3523176': '(865) 284-8307', // SERVPRO of North Knoxville (11314 - Parent)
    },
    '11738': {
        // https://www.servpro.com/locations/fl/servpro-of-downtown-orlando-southwest-orlando-and-east-orlando
        '3586797': '(321) 400-8629', // SERVPRO of Southwest Orlando (11738 - Parent)
        '3586796': '(689) 285-5386', // SERVPRO of East Orlando (11739 - Child of 11738)
        '3608957': '(321) 677-4938', // SERVPRO of Downtown Orlando/Team Cuthbertson (19039 - Child of 11738)
    },

    '11740': {
        // https://www.servpro.com/locations/fl/servpro-of-haines-city-polk-city
        '3586795': '(863) 334-4236', // SERVPRO of Haines City/Polk City (11740 - Parent)
    },
    '12015': {
        // https://www.servpro.com/locations/ca/servpro-of-eagle-rock-south-glendale
        '3633081': '(747) 286-7258', // SERVPRO of Eagle Rock/South Glendale (12015 - Parent)
    },
    '19008': {
        // https://www.servpro.com/locations/tn/servpro-of-downtown-nashville-team-isaacson
        '3605341': '(629) 204-7996', // SERVPRO of Downtown Nashville/Team Isaacson (19008 - Parent)
    },
    // add to do not swap in invoca (remove from invoca the removed numbers from here)
};

const DniProvider = ({ children, data }: { children: React.ReactNode, data: any }): JSX.Element => {
    const { r } = useQueryParams();
    const rInitial = Array.isArray(r) ? r[0] : r;
    const [rParam, setR] = React.useState<string | null>(rInitial as string);
    const { pathname } = useLocation();

    /*
    * This is a bit gross but can be less interdependent once the Telmetrics trial is finished
    * */
    const franchiseNumber = data?.franchise?.franchiseNumber;
    const mainPhone = data?.franchise?.yext?.mainPhone;
    const franchisesInTelmetricsTrial = Object.keys(telmetricsFranchiseMap);
    // the franchise must be in the trial
    // AND the "r" query param must match
    // otherwise, we use invoca
    const currentFranchiseIsInTelmetricsTrial = (() => !!(rParam
        && franchiseNumber
        && franchisesInTelmetricsTrial.includes(String(franchiseNumber))
        && telmetricsFranchiseMap[String(franchiseNumber)][String(rParam).toLowerCase()]
    ))();

    const [isSsr, setIsSsr] = React.useState(true);
    React.useEffect(() => {
        setIsSsr(false);
    }, []);

    const [provider, setProvider] = React.useState<DniBrand>(() => {
        if (currentFranchiseIsInTelmetricsTrial) {
            return DniBrand.TELMETRICS;
        }
        return DniBrand.INVOCA;
    });

    const trigger = React.useCallback((): void => {
        if (provider === DniBrand.INVOCA && (window as any).Invoca) {
            (window as any).Invoca.PNAPI.run();
        }
    }, [provider]);

    /*
    * Handle leaving franchise microsites
    * */
    React.useEffect(() => {
        if (!currentFranchiseIsInTelmetricsTrial) {
            setProvider(DniBrand.INVOCA);
            setR(null);
        }
    }, [currentFranchiseIsInTelmetricsTrial]);

    /*
    * Run automatically when provider changes
    * */
    React.useEffect(() => {
        trigger();
    }, [provider, trigger, pathname]);

    const value = React.useMemo(() => ({
        provider,
        setProvider,
        trigger,
        numberMap: (currentFranchiseIsInTelmetricsTrial && mainPhone) ? {
            [mainPhone]: telmetricsFranchiseMap[String(franchiseNumber)][String(rParam).toLowerCase()],
            [formatPhoneNumber(mainPhone)]: telmetricsFranchiseMap[String(franchiseNumber)][String(rParam).toLowerCase()],
            [formatPhoneLink(mainPhone)]: telmetricsFranchiseMap[String(franchiseNumber)][String(rParam).toLowerCase()],
        } : {},
    }), [currentFranchiseIsInTelmetricsTrial, mainPhone, franchiseNumber, provider, rParam, trigger]);

    return (
        <DniContext.Provider value={value}>
            {children}
            {!isSsr && provider === DniBrand.INVOCA && (
                <InvocaRuntime />
            )}
        </DniContext.Provider>
    );
};

export const useDniProvider = (): DniProviderReturnable => {
    const ctx = React.useContext(DniContext);
    if (!ctx) {
        throw new Error('No DNI Context has been provided.');
    }
    return ctx;
};

export default DniProvider;
