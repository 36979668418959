import React from 'react';

// Google Rep says that all Ads accounts are prefixed with AW and have 11 digits for later validation stage
const gtagFranchiseMap: Record<string, string> = {
    '5727': 'AW-16721452878', // SERVPRO of Dyersburg, Union City, Weakley, Gibson, Carroll Counties (https://www.servpro.com/locations/tn/servpro-of-dyersburg-union-city-weakley-gibson-carroll-counties)
    '8462': 'AW-16527813039', // SERVPRO of Jackson / Crockett County (https://www.servpro.com/locations/tn/servpro-of-jackson-crockett-county)
    '11396': 'AW-16628651437', // SERVPRO of Port St. Lucie NE (https://www.servpro.com/locations/fl/servpro-of-port-st-lucie-ne)
    '11395': 'AW-16628651437', // SERVPRO of Fort Pierce NE (https://www.servpro.com/locations/fl/servpro-of-fort-pierce-ne)
};

const getMarkup = (franchiseNumber: string|number): string => `
            <script async src="https://www.googletagmanager.com/gtag/js?id=${gtagFranchiseMap[String(franchiseNumber)]}"></script>
            <script>
               window.dataLayer = window.dataLayer || [];
               function gtag(){dataLayer.push(arguments);} 
               gtag('js', new Date()); gtag('config', '${gtagFranchiseMap[String(franchiseNumber)]}');
               gtag('event', 'conversion', {
                  'send_to': '${gtagFranchiseMap[String(franchiseNumber)]}',
                  'value': 1.0,
                  'currency': 'USD',
               });
            </script>
        `;

const MicrositeGtagProvider = ({ children, data }: { children: React.ReactNode, data: any }): JSX.Element => {
    const franchiseNumber = data?.franchise?.franchiseNumber;
    const elRef = React.useRef<HTMLDivElement|null>(null);

    React.useEffect(() => {
        if (franchiseNumber && gtagFranchiseMap[String(franchiseNumber)] && elRef.current) {
            if ('innerHTML' in elRef.current) {
                const range = document.createRange();
                range.selectNode(elRef.current);
                const documentFragment = range.createContextualFragment(getMarkup(franchiseNumber));
                elRef.current.innerHTML = '';
                elRef.current.append(documentFragment);
            }
        }
    }, [franchiseNumber]);

    if (franchiseNumber && gtagFranchiseMap[String(franchiseNumber)]) {
        return (
            <>
                <span ref={elRef} dangerouslySetInnerHTML={{ __html: getMarkup(franchiseNumber) }} />
                {children}
            </>
        );
    }

    return (
        <>
            {children}
        </>
    );
};

export default MicrositeGtagProvider;
